import React from "react"
import { graphql, Link } from 'gatsby'
import Img from "gatsby-image"
import styled from 'styled-components'

import Layout from "../components/layout-v2"
import SEO from "../components/seo"

import TheLab from '../svgs/the-lab-hero.svg'
import LongLeftArrow from '../images/white-arrow-left.svg'
import LongRightArrow from '../images/white-arrow-right.svg'

const LabArchive = ({ data: { featuredLabPost, mainLabPosts, pageContent }, pageContext: { nextPagePath, previousPagePath },}) =>  {

  const featuredPosts = featuredLabPost.nodes
  const mainPosts = mainLabPosts.nodes
  const content = pageContent.edges
  const prevLink = previousPagePath
  const nextLink = nextPagePath

    return (
        <Layout>
          {content.map(page => ( 
              <SEO 
              title={page.node.SEOmeta.metaTitle} 
              description={page.node.SEOmeta.metaDescription}
              keywords={page.node.SEOmeta.metaKeywords}
              ogTitle={page.node.SEOmeta.ogTitle} 
              ogDescription={page.node.SEOmeta.ogDescription}
              ogImage={page.node.SEOmeta.ogImage.localFile.childImageSharp.fluid}
              twitterTitle={page.node.SEOmeta.twitterTitle} 
              twitterDescription={page.node.SEOmeta.twitterDescription}
              twitterImage={page.node.SEOmeta.twitterImage.localFile.childImageSharp.fluid}
              />
          ))}
          <MainPage>
            <TopSection>
              <h1>The Lab</h1>
              <TheLab/>
              <p>Welcome to the White Lion Lab, where we're putting research based marketing intelligence to work.</p>
            </TopSection>
            
            <FeaturedSection>
              <h2>Featured Article</h2>
              {featuredPosts.map(featuredPost => {
                return (
                  <article
                  itemScope
                  itemType="http://schema.org/Article"
                  >
                    <div>
                      <Link to={`/lab/${featuredPost.slug}/`} itemProp="url">
                        <Img fluid={featuredPost.featuredImage.node.localFile.childImageSharp.fluid} alt={featuredPost.featuredImage.node.title}/>
                      </Link>
                    </div>
                    <div>
                      <Link to={`/lab/${featuredPost.slug}/`}><h3 dangerouslySetInnerHTML={{ __html: featuredPost.title }} itemProp="headline" /></Link>
                      <div class="excerpt" dangerouslySetInnerHTML={{ __html: featuredPost.excerpt }} itemProp="description" />
                      <p class="author">By {featuredPost.LabAuthor.labAuthorName}</p>
                    </div>
                  </article>
                )
              })}
            </FeaturedSection>
            <MainLoop>
              <h2>Lab Articles</h2>
              {mainPosts.map(mainPost => {
                return (
                  <article
                  itemScope
                  itemType="http://schema.org/Article"
                  >
                    <Link to={`/lab/${mainPost.slug}/`} itemProp="url">
                      <Img fluid={mainPost.featuredImage.node.localFile.childImageSharp.fluid} alt={mainPost.featuredImage.node.title}/>
                    </Link>
                    <Link to={`/lab/${mainPost.slug}/`}><h3 dangerouslySetInnerHTML={{ __html: mainPost.title }} itemProp="headline" /></Link>
                    <div class="excerpt" dangerouslySetInnerHTML={{ __html: mainPost.excerpt }} itemProp="description" />
                    <p class="author">By {mainPost.LabAuthor.labAuthorName}</p>
                  </article>
                )
              })}
            </MainLoop>
            <Pagination>
              <div className={previousPagePath && ( "active" )}>
                <Link to={previousPagePath} className={"prev-link"}></Link>  
              </div>
              <div className={nextPagePath && ( "active" )}>
                <Link to={nextPagePath} className={"next-link"}></Link>
              </div>
            </Pagination> 
          </MainPage>
        </Layout>
    );
          
}

const MainPage = styled.div`
  padding: 0 20px;
`

const TopSection = styled.section`
  max-width: 1170px;
  width: 100%;
  padding: 0px;
  margin: 0 auto;
  border-bottom: 10px solid #DB4140;
  h1 {
    font-family: Nobel, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 100px;
    text-align: center;
    margin-bottom: -50px;
    color: #2B2520;
  }
  p {
    font-family: Nobel, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 50px;
    line-height: 60px;
    color: #2B2520;
    max-width: 970px;
    margin: 70px auto;
  }
  @media(max-width:1230px) {
    max-width: 700px;
    h1 {
      font-size: 60px;
      margin-bottom: 0;
    }
    p {
      font-size: 36px;
      line-height: 46px;
    }
  }
  @media(max-width:767px) {
    max-width: 500px;
    border-bottom: 5px solid #DB4140;
    h1 {
      font-size: 30px;
      line-height: 42px;
      margin-bottom: -20px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      margin: 30px auto;
    }
  }
`

const FeaturedSection = styled.section`
  max-width: 1170px;
  width: 100%;
  padding: 0px;
  margin: 0 auto;
  h2 {
    font-family: Nobel, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 36px;
    line-height: 50px;
    color: #2B2520;
    margin-top: 70px;
    margin-bottom: 30px;
  }
  article {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    > div {
      a {
        text-decoration: none;
      }
      img {
        transition: opacity 500ms ease 0s, transform 1s ease 0s !important;
      }
      &:first-child {
        max-width: 670px;
        width: 100%;
        max-height: 400px;
      }
      &:last-child {
        max-width: 470px;
        width: 100%;
        h3 {
          font-family: Nobel, sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 40px;
          line-height: 50px;
          color: #2B2520;
          margin-top: 0;
          margin-bottom: 20px;
          transition-duration: 0.3s;
        }
        div.excerpt {
          p {
            font-family: "Work Sans";
            font-size: 24px;
            line-height: 34px;
            font-weight: 400;
            color: #777C7C;
            margin-top: 0px;
            margin-bottom: 20px;
          }
        }
        p.author {
          font-family: "Work Sans";
          font-size: 16px;
          line-height: 34px;
          font-weight: 400;
          color: #2B2520;
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
      h3 {
        color: rgb(219, 65, 64) !important;
      }
    }
  }
  @media(max-width:1230px) {
    max-width: 700px;
    h2 {
      text-align: center;
    }
    article {
      flex-wrap: wrap;
      > div {
        &:first-child {
          max-width: 100%;
          overflow: hidden;
          margin-bottom: 35px;
        }
        &:last-child {
          max-width: 100%;
        }
      }
    }
  }
  @media(max-width:767px) {
    max-width: 500px;
    h2 {
      margin-top: 30px;
      margin-bottom: 25px;
      font-size: 24px;
      line-height: 34px;
    }
    article {
      > div {
        &:first-child {
          margin-bottom: 10px;
        }
        &:last-child {
          h3 {
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 5px;
          }
          div.excerpt {
            p {
              font-size: 18px;
              line-height: 28px;
              margin-bottom: 0;
            }
          }
          p.author {
            font-size: 16px;
          }
        }
      }
    }
  }
`

const MainLoop = styled.section`
  max-width: 1170px;
  width: 100%;
  padding: 0px;
  margin: 0 auto;
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 10px solid #DB4140;
  h2 {
    font-family: Nobel, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 36px;
    line-height: 50px;
    color: #2B2520;
    margin-top: 70px;
    margin-bottom: 30px;
    width: 100%;
  }
  article {
    max-width: 570px;
    width: 100%;
    margin-bottom: 50px;
    .gatsby-image-wrapper {
      max-height: 340px;
      margin-bottom: 30px;
      img {
        transition: opacity 500ms ease 0s, transform 1s ease 0s !important;
      }
    }
    a {
      text-decoration: none;
    }
    h3 {
      font-family: Nobel, sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 30px;
      line-height: 40px;
      color: #2B2520;
      margin-top: 0;
      margin-bottom: 0px;
      transition-duration: .3s;
    }
    div.excerpt {
      p {
        font-family: "Work Sans";
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        color: #777C7C;
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }
    p.author {
      font-family: "Work Sans";
      font-size: 16px;
      line-height: 34px;
      font-weight: 400;
      color: #2B2520;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
      h3 {
        color: rgb(219, 65, 64) !important;
      }
    }
  }
  @media(max-width:1230px) {
    max-width: 700px;
    h2 {
      text-align: center;
    }
    article {
      max-width: 100%;
    }
  }
  @media(max-width:767px) {
    max-width: 500px;
    margin-top: 20px;
    h2 {
      margin-top: 30px;
      margin-bottom: 25px;
      font-size: 24px;
      line-height: 34px;
    }
    article {
        .gatsby-image-wrapper {
          margin-bottom: 10px;
        }
        h3 {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 5px;
        }
        div.excerpt {
          p {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 0;
          }
        }
        p.author {
          font-size: 16px;
        }
    }
  }
`

const Pagination = styled.section`
  max-width: 1170px;
  width: 100%;
  padding: 0px;
  margin: 110px auto;
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    height: 48px;
    width: 48px;
    margin: 0 10px;
    a {
      display: block;
      height: 48px;
      width: 48px;
      background-color: #EAEAEA;
      background-size: 50%;
      background-position: center;
      background-repeat: no-repeat;
      transition-duration: .3s;
    }
    &.active {
      a {
        background-color: #DB4140;
        &:hover {
          background-color: #2B2520;
          cursor: pointer;
        }
      }
    }
  }
  .prev-link {
    background-image: url(${LongLeftArrow});
  }
  .next-link {
    background-image: url(${LongRightArrow});
  }
`

export default LabArchive

export const pageQuery = graphql`
  query WordPressLabArchive($offset: Int!, $postsPerPage: Int!) {
    featuredLabPost: allWpLabPost(
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "featured"}}}}}
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        title
        excerpt
        slug
        featuredImage {
          node {
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 670, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        LabAuthor {
          labAuthorName
        }
      }
    }
    mainLabPosts: allWpLabPost(
      filter: {categories: {nodes: {elemMatch: {slug: {ne: "featured"}}}}}
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        title
        excerpt
        slug
        featuredImage {
          node {
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 570, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        LabAuthor {
          labAuthorName
        }
      }
    }
    pageContent: allWpCustomPage(filter: {databaseId: {eq: 1857}}) {
      edges {
        node {
          SEOmeta {
              metaTitle
              metaDescription
              metaKeywords
              ogTitle
              ogDescription
              ogImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1320, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              twitterTitle
              twitterDescription
              twitterImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1320, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
        }
      }
    }
  }
`